<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                    class="mt-1"
                    btnTitle="Go Back"
                    title="Edit Payment"
                    @onClickCloseButton="goToList"
                />

                <div class="row mt-1">

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Voucher Date</label>
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="payment.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label text-end">Voucher No</label>
                        <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input" readonly="readonly">
                        <div
                            class="input-group input-group-merge invoice-edit-input-group has-validation"
                            :class="{'is-invalid':isEmptyVoucher}"
                        >
                            <div class="input-group-text">
                                <span :class="{'text-danger':isEmptyVoucher}">{{ payment.voucher_prefix }}-</span>
                            </div>
                            <input
                                type="text"
                                class="form-control invoice-edit-input"
                                aria-describedby="validationVoucherNameFeedback"
                                placeholder=""
                                v-model="payment.voucher_serial"
                                :class="{'is-invalid': isEmptyVoucher}"
                            >
                        </div>
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Paid from: </label>
                        <v-select
                                placeholder="Select Cash & Bank Account"
                                class="w-100"
                                v-model="credit.account_head_id"
                                :options="cashAndBank"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Payment Ref</label>
                        <input
                                type="text"
                                class="form-control invoice-edit-input"
                                placeholder="Payment Reference"
                                v-model="payment.payment_ref">
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Payment Ref Date </label>
                        <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="payment.payment_ref_date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Project</label>
                        <v-select
                                placeholder="Select Project"
                                v-model="credit.project_id"
                                :options="projects"
                                label="name"
                                :reduce="name => name.id"
                                @option:selected="onSelectProject(true)"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Donors</label>
                        <v-select
                                placeholder="Select Donor"
                                class="w-100"
                                :options="donors"
                                label="name"
                                :reduce="name => name.id"
                                v-model="credit.donor_id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Cost Centre</label>
                        <v-select
                                placeholder="Select Cost Centre"
                                v-model="credit.cost_centre_id"
                                :options="costCentres"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                      <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Party: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                                  <i class="fas fa-search"></i>
                              </span>
                      </div>

                      <AsyncSelect
                          placeholder="Select Party"
                          v-model="contactProfile"
                          :api-service="fetchContactProfiles"
                          :format-label="formatPatientLabel"
                      />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Business</label>
                        <v-select
                                placeholder="Select Business"
                                class="w-100"
                                :options="businesses"
                                label="name"
                                :reduce="name => name.id"
                                v-model="credit.business_id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">PR / PO Reference</label>
                        <div class="input-group mb-3">
                            <input v-model="payment.txn_no" type="text" class="form-control" placeholder="PR / PO Referance">
                            <button @click="openPrPoModal()" class="btn btn-outline-secondary" type="button" id="button-addon2">Select</button>
                        </div>
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                      <label for="colFormLabel" class="col-form-label">Tag Register</label>
                      <AsyncSelect
                          placeholder="Select Tag Register"
                          v-model="tagRegister"
                          :api-service="fetchTagRegisterList"
                          :format-label="formatPatientLabel"
                      />
                    </div>
                </div>
            </div>
            <div class="mt-2 px-2 pb-2">
                <template v-for="(item, index) in payment.ledgers" :key="index">
                    <AddFormElement
                            class="mb-2"
                            :accountHeads="accountHeads"
                            :ngoabHeads="ngoabHeads"
                            :programs="programs"
                            :projects="projects"
                            :costCentres="costCentres"
                            :contactProfiles="contactProfiles"
                            :businesses="businesses"
                            :paymentDate="payment.date"
                            :fd6Donors="fd6Donors"
                            v-if="item.credit == null || item.credit === 0"
                            :index="index"
                            :item="item"
                            @onClose="onClose"
                            @isLoading="isDataLoading = true"
                            @loaded="isDataLoading = false"
                            @onClickToApplyTdsVds="() => onClickToApplyTdsVds(item)"
                    />
                </template>

                <div class="row me-1 mt-3">
                    <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end" :value="totalDebit"></div>
                    <!-- <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control" :value="totalCredit"></div> -->
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="attachment" class="form-label fw-bold">Attachment</label>
                            <BlobFileViewer :attachments="payment.attachments" />
                            <FileUpload
                                    :is-drag-active="true"
                                    button-text="Upload Documents"
                                    v-model="payment.attachments"
                            >
                            </FileUpload>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="payment.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="my-2 text-center">
                    <hr class="mb-1">
                    <button
                            :disabled="paymentLoading || isEmptyVoucher"
                            @click="updatePayment()"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="paymentLoading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Update
                    </button>
                    <button
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <!-- PR PO Modal -->
            <PrPoModal
                    :lists="prPoLists"
                    ref="prPoModal"
                    @selectedPrPo="selectedPrPo"
            />
            <Loader v-if="isDataLoading"/>
        </div>

        <VoucherValidationModal
                ref="voucherValidationModal"
                :voucherQuery="voucherQuery"
                @on-press-ok="setVoucherNumberAndDate(true)"
        />
        <ContactDropdownFilter
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :isFilterable="true"
            :companyRoles="companyRoles"
        />

        <PaymentTdsVdsModal
            :data="itemDetails"
            :tdsRateList="tdsRateList"
            :vdsRateList="vdsRateList"
            @onSaveTdsVds="onSaveTdsVds"
            v-if="$store.state.isModalOpen"
            :tdsPayableHeads="tdsPayableHeads"
            :vdsPayableHeads="vdsPayableHeads"
            :accountHeads="accountHeads"
        />
    </div>
</template>

<script>
import handlePayment from '@/services/modules/payment'
import { inject } from 'vue'
import handleContact from '@/services/modules/contact'
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/ngo/payment/AddFormElement'
import PrPoModal from '@/components/molecule/ngo/payment/PrPoModal'
import AddButton from '@/components/atom/AddButton'
import {editVoucherNumber, generateQuery, generateVoucherNumber} from "@/services/utils/voucherNumberGenerator";
import handleNGOPrograms from "@/services/modules/ngo/program";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleActivities from "@/services/modules/ngo/activity";
import FileUpload from "@/components/atom/FileUpload";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import handleProjects from "@/services/modules/procurement/project";
import Loader from '@/components/atom/LoaderComponent'
import handleFD6Projects from "@/services/modules/procurement/fd6";
import handleCBusinesses from '@/services/modules/businesses'
import handleTagRegister from '@/services/modules/scm/tag-register'
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";
import handleJournal from '@/services/modules/journal'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleRole from "@/services/modules/role";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import {useStore} from "vuex";
import handleFD6s from "@/services/modules/procurement/fd6";
import handleTdsVds from '@/services/modules/procurement/tdsVds'
import handlePurchase from '@/services/modules/purchase'
import { generateUUID } from '@/services/utils/global'
import PaymentTdsVdsModal from '@/components/molecule/ngo/payment/PaymentTdsVdsModal'
import cloneDeep from 'lodash/cloneDeep'
import round from 'lodash/round'
import handlePurchaseAndSales from "@/services/modules/purchase";
import { usePayment } from "@/services/modules/purchase/payment";

const { fetchTdsVdsPayment, updateTdsVdsPayment } = handlePurchaseAndSales();
const { fetchTdsRateList, fetchVdsRateList } = handleTdsVds();
const { getAccountHeadBySlag } = handlePurchase()
const { formatPaymentTransactions } = usePayment();

export default {
    name: 'PaymentEdit',
    components: {
        ContactDropdownFilter,
        PaymentTdsVdsModal,
        AsyncSelect,
        VoucherValidationModal,
        TitleButton,
        AddFormElement,
        PrPoModal,
        AddButton,
        FileUpload,
        BlobFileViewer,
        Loader,
    },
    data: () => ({
        accountHeads: [],
        tagRegister : null,
        contactProfile : null,
        companyRoles : [],
        contactProfiles : [],
        projects : [],
        programs : [],
        donors : [],
        costCentres : [],
        businesses : [],
        prPoLists : [],
        activities : [],
        ngoabHeads : [],
        fd6Donors : [],
        cashAndBank : [],
        saveNewLoader: false,
        isDataLoading: false,
        isEmptyVoucher: false,
        credit: {},
        payment: {
            mop_account_head_id:'',
            company_id:'',
            contact_profile_id: null,
            project_id : null,
            program_id : null,
            donor_id: null,
            cost_centre_id: null,
            txn_no: null,
            date: '',
            note: '',
            mop_references: 'mop',
            voucher_no : '',
            voucher_prefix : '',
            voucher_serial : '',
            voucher_type: 'payment_voucher',
            payment_ref : '',
            payment_ref_date : '',
            attachments: [],
            ledgers: []
        },
        itemDetails: null,
        tdsRateList: [],
        vdsRateList: [],
        tdsPayableHeads: [],
        vdsPayableHeads: [],
        paymentMasterInfo: null,
        paymentMaster: null
    }),
    computed: {
        isLoading () {
            return this.paymentLoading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.payment.ledgers
        },
        totalDebit () {
            let total = 0;
            this.general.map(i => {
                if(i.debit) total += i.debit
            })
            return total
        },
        voucherQuery () {
            return generateQuery(this.$route.params.companyId, 'payment_voucher', 'general_payment');
        },
        totalNetPayable () {
            return this.general.reduce((total, item) => total + (item.net_payable_amount || 0), 0);
        }
    },

    watch: {
        'payment.voucher_prefix':function () {
            this.concatVoucher()
        },

        'payment.voucher_serial':function () {
            this.concatVoucher()
        },
        contactProfile(newContact) {
          if(! newContact) {
            this.credit.contact_profile_id = null;
            return;
          }
          this.credit.contact_profile_id = newContact.id;
        }
    },

    methods: {
        onClickToApplyTdsVds (item) {
            this.itemDetails = item;
            this.$store.state.isModalOpen = true;
        },
        async getAccountHeads (slug) {
            await getAccountHeadBySlag(slug, this.getQuery()).then(res => {
                if(! res.status) return;
                this.setAccountHeads(res.data, slug);
            })
        },
        setAccountHeads (data, slug){
            if(slug === 'tds_payable') {
                this.tdsPayableHeads = data;
            }

            if(slug === 'vds_payable') {
                this.vdsPayableHeads = data;
            }
        },
        getQuery () {
            let query = '?company_id=' + this.$route.params.companyId;
            return query;
        },
        async getTdsRates () {

            await fetchTdsRateList(this.getQuery()).then(res => {
                if(res.status){
                    this.tdsRateList = res.data;
                }
            }).catch(err => {
                console.log(err)
            })
        },
        async getVdsRates () {

            await fetchVdsRateList(this.getQuery()).then(res => {
                if(res.status){
                    this.vdsRateList = res.data;
                }
            }).catch(err => {
                console.log(err)
            })
        },
        onSaveTdsVds (data) {
            for(let key in data) {
                this.itemDetails[key] = data[key];
            }
        },
        selectedPrPo(pr_po) {
          this.payment.txn_no = pr_po;
        },
        openPrPoModal() {
          this.$refs.prPoModal.toggleModal()
        },
        concatVoucher(){
            this.isEmptyVoucher = false;

            if(! this.payment.voucher_serial) {
                this.isEmptyVoucher = true;
                return ;
            }

            let voucherSerial = this.decimalFormat(this.payment.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
            if(voucherSerial === '') {
                this.isEmptyVoucher = true;
            }
            this.payment.voucher_serial = voucherSerial;
            this.payment.voucher_no = `${this.payment.voucher_prefix}-${voucherSerial}`;
        },

        setVoucherNumberAndDate(withoutDate = false) {
            new Promise(async (resolve, reject) => {
                try {
                    if(! withoutDate) {
                        this.payment.date = new Date().toISOString().split('T')[0]
                    }
                    let query = this.voucherQuery;
                    let voucher = await generateVoucherNumber(query);
                    this.payment.voucher_prefix = voucher.prefix;
                    this.payment.voucher_serial = voucher.serial;
                    resolve();
                } catch (err) {
                    reject(err);
                }
            })

        },

        goToList() {
            let isAllVouchers = this.$route.query.type && true;
            this.$router.push({name: (isAllVouchers ? 'voucher-list' : 'ngo-payment-voucher'),
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }

            })
        },
        onClose(index) {
            this.payment.ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.payment.ledgers.push({
                account_head_id: null,
                debit: null,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        setData() {
            let ledger = this.general.find(i => i.debit === null && i.label === null);
            
            if(ledger) {
                this.credit.account_head_id = ledger.account_head_id
                this.credit.project_id = ledger.project_id;
                this.credit.cost_centre_id = ledger.cost_centre_id;
                this.credit.donor_id = ledger.donor_id;
                this.credit.contact_profile_id = ledger.contact_profile_id;
                this.contactProfile = ledger.party;
                this.credit.business_id = ledger.business_id;
                this.payment.txn_no = ledger.txn_no;
                this.tagRegister = ledger.tag_register;
            }
        },
        getTdsVdsLedgers (accountHeadId, creditAmount, uid, label, oldLedger = null, contactProfileId = null) {
            return {
                id: oldLedger ? oldLedger.id : null,
                account_head_id: accountHeadId,
                contact_profile_id: contactProfileId,
                uid: uid,
                debit: 0,
                credit: creditAmount,
                taxable_amount: null,
                tax_rate: null,
                note: null,
                label: label
            };
        },
        getFormData () {
            let formData = new FormData();
            let previous_file_ids = [];
            this.payment._method = 'PATCH'
            this.payment.mop_account_head_id = this.credit.account_head_id
            this.credit.contact_profile_id = this.contactProfile ? this.contactProfile.id : null
            this.credit.tag_register_id = this.tagRegister ? this.tagRegister.id : null
            if(!this.payment.txn_no) this.payment.txn_no = ''
            Object.keys(this.payment).forEach(i => {
                if(i !== 'ledgers' && i !== 'attachments' && i !== 'contact_profile_id' && i !== 'business_id') {
                    formData.append(i, this.payment[i])
                }
            })
            if(this.payment.contact_profile_id) formData.append('contact_profile_id', this.payment.contact_profile_id)
            if(this.payment.attachments) {
                for(let i=0; i<this.payment.attachments.length; i++) {
                    if(typeof this.payment.attachments[i] === 'object' && this.payment.attachments[i].hasOwnProperty('id')) {
                        previous_file_ids.push(this.payment.attachments[i].id)
                    } else {
                        let key = `attachments[${i}]`;
                        let value = this.payment.attachments[i];
                        formData.append(key, value);
                    }
                }

              formData.append('previous_file_ids', JSON.stringify(previous_file_ids))
            }

            let generalLedgerData = cloneDeep(this.general).filter(ledger => ledger.account_head_id);
            
            let tagRegisterId = this.tagRegister ? this.tagRegister.id : null
            let mop_account_head_id = this.credit.account_head_id;
            const paymentGenerals = [];
            const tdsVdsLedgers = [];

            for (const generalLedger of generalLedgerData) {
                generalLedger.tag_register_id = tagRegisterId;
                generalLedger.mop_account_head_id = mop_account_head_id;

                if(generalLedger.tds_amount || generalLedger.vds_amount) {
                    generalLedger.uid = generalLedger.uid ? generalLedger.uid : generateUUID();
                }

                if(!generalLedger.label && generalLedger.uid) {
                    paymentGenerals.push(generalLedger);
                }

                if(generalLedger.tds_amount) {
                    const tdsLedger = this.getTdsVdsLedgers(generalLedger.tds_account_head_id, generalLedger.tds_amount, generalLedger.uid, 'tds_ledger', generalLedger.tds_ledger, generalLedger.contact_profile_id);
                    tdsVdsLedgers.push(tdsLedger);
                }

                if(generalLedger.vds_amount) {
                    const tdsLedger = this.getTdsVdsLedgers(generalLedger.vds_account_head_id, generalLedger.vds_amount, generalLedger.uid, 'vds_ledger', generalLedger.vds_ledger, generalLedger.contact_profile_id);
                    tdsVdsLedgers.push(tdsLedger);
                }

                if(generalLedger.net_payable_amount) {
                    generalLedger.debit = generalLedger.total_amount;
                }
            }

          let debitObj = generalLedgerData.find(i => (i.debit === null || i.debit === 0) && (i.label === null));
                debitObj.account_head_id = this.credit.account_head_id
                debitObj.mop_account_head_id = null;
                debitObj.project_id = this.credit.project_id
                debitObj.donor_id = this.credit.donor_id
                debitObj.cost_centre_id = this.credit.cost_centre_id
                debitObj.contact_profile_id = this.credit.contact_profile_id
                debitObj.tag_register_id = tagRegisterId
                debitObj.business_id = this.credit.business_id
                debitObj.credit = this.totalNetPayable ? this.totalNetPayable : this.totalDebit,
                debitObj.note= this.payment.note

            this.paymentMaster = cloneDeep(debitObj);
            this.paymentMaster.payment_generals = paymentGenerals;

            generalLedgerData.push(...tdsVdsLedgers);

            formData.append('general_ledgers', JSON.stringify(generalLedgerData))

            return formData;
        },
        async updatePayment () {
            
            if(! this.credit.account_head_id) {
                this.showError('Please select an Account Head');
                return;
            }

            this.concatVoucher();
            this.payment.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            
            this.paymentLoading = true

            try {
                let res = await this.editPayment(this.payment.id, data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.updatePayments();
                    setTimeout(() => {this.goToList()},1500)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                    return ;
                }

                if(err.response && err.response.status !== 406) {
                    this.showError(err.response?.data.message)
                }
                if(err.response.data.data && err.response.data.data.show_modal) {
                    this.$refs.voucherValidationModal.openModal(err.response?.data.message)
                }
            } finally {
                this.paymentLoading = false
            }
        },

        async updatePayments () {

            const oldPaymentMaster = {
                id: this.payment.id,
                voucher_no: this.payment.voucher_no,
            }

            const data = formatPaymentTransactions(this.paymentMaster, this.payment, this.$route.params.companyId, oldPaymentMaster);
            
            await updateTdsVdsPayment(oldPaymentMaster.id, data).then(res => {
                if(res.status) {
                    // TODO: add some logic here
                }
            })
        },

        onChangeDate (e) {
            this.payment.date = e.target.value
        },
        onSelectProject(resetDonor = false) {
            this.donors = [];
            if(resetDonor) this.credit.donor_id = null;
            for (let project of this.projects) {
                if(project.id === this.credit.project_id && project.party.hasOwnProperty('id')){
                    this.donors.push(project.party);
                    break;
                }
            }
        },

        onOpenContactSearchModal () {
          this.$store.state.isModalOpenThree = true;
        },
        async getCompanyRoles () {
          const res = await this.fetchCompanyDefaultRoles(`?company_id=${this.$route.params.companyId}`);
          if (res.status) {
            this.companyRoles = res.data
          }
        },
        onSearchContact (contact) {
          this.contactProfile = contact
        },
        async getAllFd6DonorLists() {
            this.isDataLoading = true
            let companyQuery = '?company_id=' + this.$route.params.companyId;
            try {
              let res = await this.fetchAllFD6Donors(companyQuery)
              if(res.status) {
                this.fd6Donors = res.data
              }
            } catch (err) {
              if(!err.response) {
                showError('Something is wrong. Check your connectivity!!')
              }
              if(err.response) {
                showError(err.response?.data.message)
              }
            }
            this.isDataLoading = false;
        },

        formatLedgerGeneral() {
            const ledgerGeneral = (this.payment.ledgers || []);
            if (!ledgerGeneral.length) return;

            const ledgers = ledgerGeneral.reduce((carry, ledger) => {

                if (!ledger.uid && ledger.credit){
                    carry.netPayableItems.push(ledger);
                    return carry;
                }

                if (!ledger.label && ledger.uid) {
                    carry.parents.push(ledger);
                    return carry;
                }

                carry.children[ledger.uid] = carry.children[ledger.uid] || [];
                carry.children[ledger.uid].push(ledger);

                return carry;
            }, { parents: [], children: {}, netPayableItems: [] });

            const parentLedgers = ledgers.parents.map((parentLedger, key) => {
                
                const paymentGeneral = this.paymentMasterInfo && this.paymentMasterInfo.general.length ? this.paymentMasterInfo.general[key] : null;
                if(paymentGeneral) {
                    parentLedger.payment_general = paymentGeneral;
                    parentLedger.tds_rate_id = paymentGeneral.tds_rate_id;
                    parentLedger.vds_rate_id = paymentGeneral.vds_rate_id;
                    parentLedger.tds_account_head_id = paymentGeneral.tds_account_head_id;
                    parentLedger.vds_account_head_id = paymentGeneral.vds_account_head_id;
                    parentLedger.discount_amount = paymentGeneral.discount_receipt_amount;
                    parentLedger.rate = paymentGeneral.rate;
                    parentLedger.quantity = paymentGeneral.quantity;
                    parentLedger.base_amount = paymentGeneral.base_amount;
                    parentLedger.bill_amount = paymentGeneral.bill_amount;
                }

                const childLedgers = ledgers.children[parentLedger.uid] || [];
                if(! childLedgers.length) return parentLedger;

                parentLedger.is_set_product = false;
                parentLedger.is_vat_added = true;
                
                for (const childLedger of childLedgers) {
                    parentLedger[childLedger.label] = childLedger;
                }

                parentLedger.total_amount = parentLedger.debit;

                parentLedger.tds_amount = parentLedger.tds_ledger ? parentLedger.tds_ledger.credit : 0;
                parentLedger.vds_amount = parentLedger.vds_ledger ? parentLedger.vds_ledger.credit : 0;
                parentLedger.net_payable_amount = round((parentLedger.debit - parentLedger.tds_amount - parentLedger.vds_amount), 2);

                parentLedger.tds_account_head_id = parentLedger.tds_ledger ? parentLedger.tds_ledger.account_head_id : null;
                parentLedger.vds_account_head_id = parentLedger.vds_ledger ? parentLedger.vds_ledger.account_head_id : null;
                parentLedger.debit = parentLedger.net_payable_amount;

                return parentLedger;
            });

            parentLedgers.push(...ledgers.netPayableItems);

            this.payment.ledgers = parentLedgers;
        },

        async getSingleTdsVdsPayment() {
            await fetchTdsVdsPayment(this.$route.params.paymentId, this.getQuery()).then(res => {
                this.paymentMasterInfo = res.data
            })
        }

    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const {decimalFormat} = figureFormatter();
        const store = useStore()
        const { fetchAllFD6Donors } = handleFD6s();

        const {
            editPayment,
            fetchSinglePayment,
            fetchCashAndBank,
            paymentLoading
        } = handlePayment()

        const {
            fetchAccountHeads
        } = handleJournal()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchProgramList,
        } = handleNGOPrograms()

        const {
           fetchCostCentreList,
        } = handleCostCentres()


        const {
            fetchActivityList,
        } = handleActivities()

        const {
            fetchProjects,
        } = handleProjects()

        const {
            fetchFD6AccountsAll
        } = handleFD6Projects()

        const {fetchBusinessList} = handleCBusinesses()
        const {fetchTagRegisterList, fetchPrPoList} = handleTagRegister()

        const { formatPatientLabel } = useAsyncDropdownHelper();
        const { fetchCompanyDefaultRoles } = handleRole();

        return {
            store,
            fetchAccountHeads,
            fetchContactProfiles,
            editPayment,
            fetchSinglePayment,
            paymentLoading,
            showError,
            showSuccess,
            fetchProgramList,
            fetchCostCentreList,
            fetchActivityList,
            fetchProjects,
            fetchFD6AccountsAll,
            fetchCashAndBank,
            fetchBusinessList,
            fetchAllFD6Donors,
            fetchTagRegisterList,
            fetchPrPoList,
            decimalFormat,
            formatPatientLabel,
            fetchCompanyDefaultRoles
        }
    },

    async mounted() {
        this.isDataLoading = true;
        let companyId = this.$route.params.companyId
        let contactId = '';
        let q = '';
        let companyQuery = '?company_id=' + companyId;
        let query = companyQuery + '&q=' + q + '&contact_id' + contactId;
        let projectQuery = companyQuery + '&with_donors=1';

        Promise.all([

            this.fetchAccountHeads(companyId, 'yes').then(res => {
                if(res.data){
                    this.accountHeads = res.data
                }
            }),
            this.fetchFD6AccountsAll(companyQuery).then(res => {
                if(res.data){
                    this.ngoabHeads = res.data
                }
            }),
            this.fetchProgramList(companyQuery).then(res => {
                if(res.data){
                    this.programs = res.data;
                }
            }),
            this.fetchCostCentreList(companyQuery).then(res => {
                if(res.data) {
                    this.costCentres = res.data
                }
            }),
            this.fetchActivityList(companyQuery).then(res => {
                if(res.data){
                    this.activities = res.data
                }
            }),
            this.fetchProjects(projectQuery).then(res => {
                if(res.data) {
                    this.projects = res.data
                }
            }),
            this.fetchCashAndBank(companyId).then(res => {
                if(res.data){
                    this.cashAndBank = res.data
                }
            }),
            this.fetchContactProfiles(query).then(res => {
                if(res.data) {
                    this.contactProfiles = res.data;
                }
            }),
            this.fetchBusinessList(companyQuery).then(res => {
              if(res.data) {
                this.businesses = res.data;
              }
            }),
            this.fetchPrPoList(companyQuery).then(res => {
              if(res.data) {
                this.prPoLists = res.data;
              }
            }),

            this.getSingleTdsVdsPayment(),

            this.fetchSinglePayment(this.$route.params.paymentId).then( async res => {
                if (res.status) {
                    this.payment = res.data

                    if(this.paymentMasterInfo){
                        this.formatLedgerGeneral();
                    }
                    
                    await this.setData();
                    this.onSelectProject();
                    let voucher = editVoucherNumber(this.payment.voucher_no);
                    this.payment.voucher_prefix = voucher.prefix;
                    this.payment.voucher_serial = voucher.serial;
                }
            }),
            this.getAllFd6DonorLists(),
            this.getAccountHeads('tds_payable'),
            this.getAccountHeads('vds_payable'),
            this.getTdsRates(),
            this.getVdsRates()
        ]).then(() => {
            this.isDataLoading = false;
        }).catch(err => {
            this.isDataLoading = false;
            this.showError(err.message)
        }).finally(() => {
            this.isDataLoading = false;
        })

    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>
